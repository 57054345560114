import { Point, Feature, FeatureCollection } from 'geojson';

export function createPointFeature(
  coordinates?: number[],
): FeatureCollection<Point> {
  const featureCollection: FeatureCollection<Point> = {
    type: 'FeatureCollection',
    features: [],
  };

  if (coordinates) {
    const point: Feature<Point> = {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Point',
        coordinates,
      },
    };

    featureCollection.features.push(point);
  }

  return featureCollection;
}
