import { GeoJSONSource, MapOptions } from 'maplibre-gl';
import NgwMap from '@nextgis/ngw-mapbox';
import bearing from '@turf/bearing';
import rhumbDestination from '@turf/rhumb-destination';
import { Heli } from './Heli';
import { createPointFeature } from './utils/createPointFeature';
import { NgwWebmapLayerAdapter } from '@nextgis/ngw-kit';

const start = async () => {
  const center = [108.4598, 51.6878];

  const mapAdapterOptions: Partial<MapOptions> = {
    fadeDuration: 0,
    style:
      'https://geoservices.nextgis.com/config/osm/osm-bright/style.json?apikey=67f622354b0e6302cb2b59f4d1b634e8',
  };

  const ngwMap = new NgwMap({
    target: 'map',
    mapAdapterOptions,
    center,
    zoom: 11,
    controls: ['ZOOM'],
    auth: { login: 'administrator', password: 'NextgisNextgis' },
    baseUrl: 'https://geonote.nextgis.com',
  });

  let lastCoord = center;

  await ngwMap.onLoad();

  // @ts-ignore - Remove map adapter layer ordering to allow landsat move down the style
  ngwMap.mapAdapter.setLayerOrder = () => {
    //
  };

  const landsat = (await ngwMap.addNgwLayer({
    id: 'landsat',
    resource: 2136,
    fit: true,
  })) as NgwWebmapLayerAdapter;
  if (landsat) {
    ngwMap.hideLayer(landsat);
  }

  const map = ngwMap.mapAdapter.map;

  if (map) {
    if (landsat) {
      // const mapLayers = map.getStyle().layers;
      // console.log(mapLayers);
      const webmapLayers = landsat.getDependLayers();
      for (const l of webmapLayers) {
        if (l.layer) {
          map.moveLayer(l.layer.layer[0], 'landcover-glacier');
        }
      }
    }

    const heli = new Heli({ map });

    const setHeliCoord = (coord: number[]) => {
      const dotSource = map.getSource('heli-point') as GeoJSONSource;

      const angle = bearing(lastCoord, coord);
      heli.rotate(angle);

      if (dotSource) {
        dotSource.setData(createPointFeature(coord));
      }
      lastCoord = coord;
    };

    map.addImage('heli', heli, { pixelRatio: 2 });

    map.addSource('heli-point', {
      type: 'geojson',
      data: createPointFeature(),
    });

    setHeliCoord([108.4598, 51.6878]);

    ngwMap.addLayer('GEOJSON', {
      id: 'layer-with-heli',
      nativeOptions: {
        type: 'symbol',
        source: 'heli-point',
        layout: {
          'icon-image': 'heli',
        },
        transition: {
          duration: 0,
          delay: 0,
        },
      },
    });

    const landsatToggleControl = ngwMap.createToggleControl({
      html: 'L',
      getStatus: () => (landsat ? ngwMap.isLayerVisible(landsat) : false),
      onClick: (status) => {
        ngwMap.toggleLayer('landsat', !status);
      },
    });
    ngwMap.addControl(landsatToggleControl, 'top-right');

    ngwMap.emitter.on('click', (ev) => {
      setHeliCoord(ev.lngLat);
    });

    let flyIntervalId: number | null = null;

    const stopHeli = () => {
      if (flyIntervalId !== null) {
        clearInterval(flyIntervalId);
      }
    };

    /**
     *
     * @param speed in km/h
     * @returns
     */
    const fly = (speed: number): (() => void) => {
      stopHeli();
      const animationDelay = 10; // ms
      const speedInCmInMs = speed * (1 / 3600000);
      const distance = speedInCmInMs * animationDelay;

      flyIntervalId = window.setInterval(() => {
        const toPoint = rhumbDestination(lastCoord, distance, heli.getAngle());
        setHeliCoord(toPoint.geometry.coordinates);
      }, animationDelay);

      return () => {
        stopHeli();
      };
    };

    fly(500);
  }
};

start();
