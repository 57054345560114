import { Map, StyleImageInterface } from 'maplibre-gl';
import HeliBodyImg from './images/heli-body.png';
import HeliWingImg from './images/heli-wing.png';

export interface HeliOptions {
  size?: number;
  map: Map;
}

export class Heli implements StyleImageInterface {
  width = 200;
  height = 200;
  data!: Uint8ClampedArray;
  private _map: Map;
  private _imageWidth = 300;
  private _imageHeight = 300;
  private _context: CanvasRenderingContext2D | null = null;
  private _imgBody?: HTMLImageElement;
  private _imgWing?: HTMLImageElement;
  private _duration = 100000;
  private _angle = 0;
  private _mapRotation = 0;
  private __updateMapRotation?: () => void;

  constructor(opt: HeliOptions) {
    this._map = opt.map;

    this.data = new Uint8ClampedArray(this.width * this.height * 4);
  }

  onAdd() {
    const canvas = document.createElement('canvas');
    canvas.width = this.width;
    canvas.height = this.height;

    const imgBody = new Image();
    const imgWing = new Image();
    imgBody.src = HeliBodyImg;
    imgWing.src = HeliWingImg;

    imgBody.onload = () => {
      this._imgBody = imgBody;
      imgWing.onload = () => {
        this._imgWing = imgWing;
        const context = canvas.getContext('2d');
        if (context) {
          this._context = context;
        }
      };
    };
    this._setupMapRotation();
  }

  onRemove() {
    const map = this._map;
    if (map && this.__updateMapRotation) {
      map.off('rotate', this.__updateMapRotation);
    }
  }

  getAngle(): number {
    return this._angle;
  }

  rotate(angle: number) {
    this._angle = angle;
    if (this._map) {
      this._map.triggerRepaint();
    }
  }

  // Call once before every frame where the icon will be used.
  render() {
    const {
      width,
      height,
      _map: map,
      _context: context,
      _imgBody: imgBody,
      _imgWing: imgWing,
      _imageWidth: imageWidth,
      _imageHeight: imageHeight,
    } = this;
    if (context && imgBody && imgWing) {
      const scaleWidth = width / imageWidth;
      const scaleHeight = height / imageHeight;

      const duration = this._duration;
      const t = (performance.now() % duration) / duration;
      const wingAngle = 360 * t;

      context.clearRect(0, 0, width, height);
      context.save();
      context.scale(scaleWidth, scaleHeight);

      context.translate(imageWidth / 2, imageHeight / 2); // translate to rectangle center
      const baseRotateAngle =
        (-(90 - this._angle + this._mapRotation) * Math.PI) / 180;
      context.rotate(baseRotateAngle);
      context.translate(-imageWidth / 2, -imageHeight / 2); // translate back

      context.drawImage(imgBody, 0, 0);
      context.translate(imageWidth / 2, imageHeight / 2); // translate to rectangle center

      context.rotate(wingAngle - baseRotateAngle);
      context.translate(-imageWidth / 2, -imageHeight / 2); // translate back
      context.drawImage(imgWing, 0, 0);
      context.restore();

      this.data = context.getImageData(0, 0, width, height).data;

      map.triggerRepaint();
    }

    return true;
  }

  private _updateMapRotation() {
    const map = this._map;
    if (map) {
      this._mapRotation = map.getBearing();
      map.triggerRepaint();
    }
  }

  private _setupMapRotation() {
    const map = this._map;
    this._updateMapRotation();
    if (map) {
      this.__updateMapRotation = () => this._updateMapRotation();
      map.on('rotate', this.__updateMapRotation);
    }
  }
}
